<template>
  <v-app>
    <v-main>
      
      <SampleForm />
    </v-main>
  </v-app>
</template>

<script>
import SampleForm from './components/SampleForm.vue';
import { useAddToHomescreen } from '@owliehq/vue-addtohomescreen';

export default {
  name: 'App',

  setup() {
    console.log('App setup');
    useAddToHomescreen({ buttonColor: 'red' });
  },

  components: {
    SampleForm,
  },

  data: () => ({
    //
  }),
}
</script>
