<template>
    <v-container>
    <v-row class="text-center">
        <v-col cols="12">
            <form @submit.prevent="submitForm">
                <label for="name">Name:</label>
                <input type="text" id="name" v-model="formData.name" placeholder="Name" required />
                <br>
                <label for="email">Email:</label>
                <input type="text" id="email" v-model="formData.email" placeholder="Email" required>
                <br>
                <button type="submit">Save</button>
            </form>
            <ul>
                <li v-for="form in storedForms" :key="form.id">
                    {{ form.name }} - {{ form.email }}
                    <button @click="editForm(form.id)">Edit</button>
                    <button @click="deleteForm(form.id)">Delete</button>
                </li>
            </ul>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import { idbKeyval } from '../idbSampleForm.js';

export default {
    data() {
        return {
            formData: {
                name: '',
                email: ''
            },
            storedForms: []
        };
    },
    async created() {
        this.storedForms = await idbKeyval.getAll();
    },
    methods: {
        async submitForm() {
            if (this.formData.id) {
                await idbKeyval.update(this.formData.id, this.formData);
            } else {
                await idbKeyval.set(this.formData);
            }
            this.storedForms = await idbKeyval.getAll();
            this.formData = { name: '', email: '' };
        },
        async editForm(id) {
            const form = await idbKeyval.get(id);
            this.formData = { ...form };
        },
        async deleteForm(id) {
            await idbKeyval.delete(id);
            this.storedForms = await idbKeyval.getAll();
        }
    }
};
</script>