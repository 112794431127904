import { openDB } from 'idb';

const dbPromise = openDB('mySampleFormStore', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('forms')) {
            db.createObjectStore('forms', { keyPath: 'id', autoIncrement: true });
        }
    },
});

export const idbKeyval = {
    async get(key) {
        return (await dbPromise).get('forms', key);
    },
    async getAll() {
        return (await dbPromise).getAll('forms');
    },
    async set(val) {
        const plainVal = JSON.parse(JSON.stringify(val));
        return (await dbPromise).add('forms', plainVal);
    },
    async update(id, val) {
        const plainVal = JSON.parse(JSON.stringify(val));
        console.log('update id', id, 'val', plainVal);
        return (await dbPromise).put('forms', plainVal);
    },
    async delete(key) {
        console.log('delete key', key);
        return (await dbPromise).delete('forms', key);
    },
    async clear() {
        return (await dbPromise).clear('forms');
    },
};
